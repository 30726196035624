<template>
    <div>
            <div class="myAwardContainer" v-if="$route.path === '/wuyiMyAward'">
            <div v-wechat-title="$route.meta.title"></div>
                  <div class="noAward" v-if="!isPrize">
                        <div class="textTop">您还未获得任何奖励哦！</div>
                        <div class="textBottom" @click="$router.push(`/wuyiIndex/wuyiUserInfo?userId=${userId}&token=${token}`)">去往活动任务页完成任务获取福袋奖励</div>
                  </div>
                  <div class="myAwardContent" v-else>
                        <div class="myAwardTop">红包为五一活动收入总金额，红包提现以实际可提现金额为准</div>
                        <div class="myAwardMain">
                            <div class="redBag" v-show="is_redBag">
                                <div class="redBagText">红包：{{redBag}}元</div>
                                <div class="goMoney" @click="goMoney">去提现</div>
                            </div>
                            <van-divider v-show="is_redBag"/>
                            <div class="total" v-show="is_score">
                                <div class="totalText">积分：{{score}}</div>
                                <div class="gototal" @click="goScore">去看看</div>
                            </div>
                            <van-divider v-show="is_score"/>
                            <div class="coupon" v-show="is_coupon">
                                <div class="totalText">优惠券：{{coupon}}张</div>
                                <div class="gocoupon" @click="goCoupon">去看看</div>
                            </div>
                            <van-divider v-show="is_coupon"/>
                            <div class="meimei" v-show="is_meimei">
                                <div class="totalText">美枚：{{meimei}}枚</div>
                                <div class="gomeimei" @click="goMeimei">去看看</div>
                            </div>
                            <van-divider v-show="is_meimei"/>
                            <div class="hot" v-show="is_hot">
                                <div class="totalText">上热门次数：{{hot}}次</div>
                                <div class="gouse_hot" @click="goHot">去使用</div>
                            </div>
                            <van-divider v-show="is_hot"/>
                            <van-collapse  class="member" >
                                <div class="matter" v-show="real !==''">
                                    <div class="matterHeader">
                                    <div class="matterHeaderLeft">实物大礼</div>
                                    <div 
                                        class="goGet" 
                                        @click="$router.push(`/wuyiMyAward/wuyiUserInfo?userId=${userId}&token=${token}&prize_id=${prize_id}`)" 
                                        v-if="real.status == 1"
                                    >
                                        去领取
                                    </div>
                                    <div class="sign" v-else>请保持电话畅通，以免错过签收</div>
                                    </div>
                                    <div class="matterMain">
                                        <img src="../static/image/myAward/prize_s@2x.png" class="prizeSmall" v-if="real.reward == '魔爪XE'">
                                        <img src="../static/image/myAward/prize_l@2x.png" class="prizeBig"  v-else />
                                    </div>
                                </div>
                            </van-collapse>
                        </div>
                  </div>
            </div>
            <div v-else>
                  <router-view :key="$route.fullPath"></router-view>
            </div>
    </div>
</template>

<script>
import utils from '../utils'
import BScroll from "better-scroll";
import Vue from "vue";
import { Divider, Collapse, CollapseItem } from "vant";
Vue.use(Divider);
Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
    name: "myAward",
    data() {
        return {
            redBag:0,
            score:0,
            hot:0,
            coupon:0,
            meimei:0,
            is_redBag:false,
            is_score:false,
            is_hot:false,
            is_coupon:false,
            is_meimei:false,
            real:'',
            prize_id:0,
            isPrize:false
        };
    },
    
    created(){
        //获取userId和token
        this.userId = utils.getRequest().userId ? utils.getRequest().userId : "";
        this.token = utils.getRequest().token ? utils.getRequest().token : "";
        //去登录
        if(this.userId == '' || this.token == ''){
            window.location.href = "https://meisheapp.com?command=enterLogin";
            return false
        }

        window.APPHREF =  function (userId, token) {
            window.location.href=`/wuyiIndex?userId=${userId}&token=${token}`
        }
    },
    async mounted() {

        //奖品种类
         let {app_key, date, sign} = utils.getSign('v1/TwelveDay/drawlist')
        let {errNo,data,message} = await this.$api.myAwardList(app_key,date,sign,this.token,this.userId)
        if(errNo === 0){
            if(data.length != 0){
                this.isPrize = true 
                data.forEach(item => {
                    if(item.type == 1){
                        this.is_redBag = true
                        this.redBag =  item.reward
                    }else if(item.type == 2){
                        this.is_coupon = true
                        this.coupon = item.reward
                    }else if(item.type == 3){
                        this.is_score = true
                        this.score = item.reward
                    }else if(item.type == 4){
                        this.is_meimei = true
                        this.meimei = item.reward
                    }else if(item.type == 5){
                        this.is_hot = true
                        this.hot = item.reward
                    }else if(item.type == 6){
                        this.coupon = item.reward
                    }
                });
            }else{
                this.isPrize = false
            }
        }


    },
    methods: {
        
        //积分列表
        goScore(){
            window.location.href = `https://m.meisheapp.com/taskForAppNew/v5/integralList.html?userId=${this.userId}&token=${this.token}`
        },
        //去提现
        goMoney(){
            window.location.href =`${utils.webAPPUrl}?userId=${this.userId}&token=${this.token}`
        },
        //优惠券
        goCoupon(){
            window.location.href =`${utils.couponUrl}?userId=${this.userId}&token=${this.token}`
        },
        //上热门
        goHot(){
            if(this.userId == '' || this.token == ''){
                window.location.href ="http://meisheapp.com?command=enterLogin";
                return false
            }else{
                utils.JOIN_ACTIVITY('', this.activityName, this.activityId, 2, 7, '')
            }
        },
        //美枚
        goMeimei(){
            window.location.href = `https://community.meisheapp.com/meishe/meimei/index.php?command=getMeimeiList&userId=${this.userId}&token=${this.token}`
            
        }

    },
};
</script>
<style scoped>
.van-divider {
    margin: 0;
}
/deep/.van-cell {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 10px 0;
}
/deep/.van-collapse-item--border::after {
    border: transparent;
}
/deep/.van-collapse-item__content {
    padding: 0;
}
/deep/.van-hairline--top-bottom:after{
    border-width:0;
}
.noAward img {
    width: 74px;
    height: 56px;
}
.noAward {
    text-align: center;
}
.textTop {
    width: 375px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    text-align: center;
    margin-top: 27px;
}
.textBottom {
    width: 323px;
    height: 51px;
    border-radius: 4px;
    border: 1px solid #F5574B;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #F5574B;
    line-height: 51px;
    text-align: center;
    margin: 20px auto;
}
.myAwardContainer {
    /* height: 100vh; */
}
.myAwardTop {
    width: 375px;
    height: 41px;
    background: rgba(245, 87, 75, 0.1);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f5574b;
    line-height: 41px;
    text-align: center;
}
.myAwardMain {
    padding: 0 15px;
    box-sizing: border-box;
}
.redBag,.hot,.coupon,.meimei {
    width: 100%;
    height: 55px;
    line-height: 55px;
}

.redBagText {
    width: 130px;
    /* height: 22px; */
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    float: left;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.goMoney,.gocoupon,.gomeimei,.gouse_hot{
    width: 58px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #f5574b;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f5574b;
    float: right;
    line-height: 25px;
    text-align: center;
    margin-top: 15px;
}
.total {
    width: 100%;
    height: 55px;
    line-height: 55px;
}
.totalText {
    /* width: 110px; */
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    float: left;
}
.gototal {
    width: 58px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #f5574b;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f5574b;
    float: right;
    line-height: 25px;
    text-align: center;
    margin-top: 15px;
}
.member {
    width: 100%;
    height: 55px;
    line-height: 55px;
}
.memberContainer {
    height: 100px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 5px 15px 11px;
    overflow: hidden;
    box-sizing: border-box;
}
.memberItem {
    height: 17px;
    width: 100%;
    line-height: 17px;
    margin-top: 10px;
}
.memberText {
    float: left;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 17px;
}
.memberTextLeft {
    color: #999;
}
.memberTextRight {
    color: #333;
}
.memberTime {
    width: 75px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999;
    line-height: 17px;
    float: right;
}
.hot {
    width: 100%;
    height: 55px;
    line-height: 55px;
}
.hotContainer {
    height: 124px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 5px 15px 11px;
    overflow: hidden;
    box-sizing: border-box;
}
.hotItem {
    height: 17px;
    width: 100%;
    line-height: 17px;
    margin-top: 10px;
}
.hotText {
    float: left;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 17px;
}
.hotTextLeft {
    color: #999;
}
.hotTextRight {
    color: #333;
}
.hotTime {
    width: 75px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999;
    line-height: 17px;
    float: right;
}
.matter {
    margin-top: 10px;
    position: relative;
    padding-bottom: 25px;
}
.matterHeader {
    width: 100%;
    height: 50px;
}
.matterHeaderLeft {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    float: left;
    line-height: 22px;
}
.goGet {
    width: 58px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #f5574b;
    float: right;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f5574b;
    line-height: 25px;
    text-align: center;
}
.prizeBig {
    display: block;
    width: 305px;
    height: 82px;
    margin: 0 auto;
}
.prizeSmall {
    display: block;
    width: 129px;
    height: 82px;
    margin: 0 auto;
}
.sign {
    width: 176px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f5574b;
    line-height: 17px;
    float: right;
}
</style>